.prompt {
  margin-top: 150px;
  padding: 50px 150px;
  min-height: 1000px;
}

.prompt button {
  margin: 0 10px;
  padding: 5px 12px;
  cursor: pointer;
}
.prompt button.active {
  scale: 1.3;
  background-color: rgb(120, 192, 240);
}

.prompt .list {
  margin-top: 15px;
  border: 1px solid white;
  color: white;
}

.prompt .data {
  border: 1px solid white;
  display: flex;
  align-items: flex-start;
}

.prompt .data img {
  width: auto;
  margin-right: 15px;
  max-height: 250px;
  max-width: 250px;
}

.prompt .data img:hover {
  transform: translateX(60px);
  scale: 2.5;
  border: 2px solid white;
  box-sizing: border-box;
}
.prompt .pixabay-images img:hover {
  transform: translateX(-60px);
}

.prompt .data button {
  margin-top: 50px;
}
.prompt .data button:hover {
  color: red;
  cursor: pointer;
  font-weight: 800;
}

.prompt .data-info {
  box-sizing: border-box;
  padding: 20px 10px;
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.prompt .data-count {
  margin-top: 15px;
  font-size: 20px;
  color: white;
}

.prompt .data-info p{
  line-height: 2;
}

.prompt h5 {
  color: white;
}

.highlight {
  box-sizing: border-box;
  border: 7px solid yellow; /* 노란색 테두리 적용 */
}


.entire-history {
  margin-top: 150px;
  text-align: center;
}
.entire-history .images-container {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  gap: 10px;
}

.entire-history img {
  width: 100%;
  max-width: 512px;
  height: auto;
  display: block;
}

.entire-history .pagination {
  display: flex;
  justify-content: center;
  margin-top: 20px;
  margin-bottom: 50px;
}

.entire-history .pagination button {
  margin: 0 5px;
  padding: 8px 15px;
  border: none;
  border-radius: 5px;
  background-color: #f0f0f0;
  cursor: pointer;
}

.entire-history .pagination button.active {
  background-color: #007bff;
  color: white;
}

.entire-history .pagination button[disabled] {
  cursor: default;
  background-color: #f0f0f0;
  color: #000;
}


@media (max-width: 768px) {
  .prompt {
    padding: 5px;
    margin-top: 50px;
  }

  .prompt .data img{
    width: 150px;
  }

  .prompt button {
    margin: 0 4px;
    padding: 5px 10px;
    cursor: pointer;
  }

  .prompt .data-info {
    padding: 5px;
  }

  .prompt p {
    line-height: 1.2;
  }

  .entire-history {
    margin-top: 20px;
  }
  .entire-history .images-container {
    grid-template-columns: repeat(2, 1fr);
    gap: 10px;
  }
  .entire-history .pagination {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    margin-top: 20px;
    margin-bottom: 30px;
    gap: 10px;
  }
}