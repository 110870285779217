.KonvaCanvas {
  margin: 0 auto;
  width: 512px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border: 5px dashed white;
  box-sizing: content-box !important;
}

.KonvaCanvas .KonvaStage {
  width: 512px;
  height: 512px;
  box-sizing: border-box;
  align-items: center;
  justify-content: center;
  text-align: center;
  margin: 0 auto;
  background-size: 40px 40px;
  background-image:
    linear-gradient(45deg, rgba(238, 238, 238, 0.6) 25%, transparent 25%, transparent 75%, rgba(238, 238, 238, 0.6) 75%, rgba(238, 238, 238, 0.6)),
    linear-gradient(45deg, rgba(238, 238, 238, 0.6) 25%, transparent 25%, transparent 75%, rgba(238, 238, 238, 0.6) 75%, rgba(238, 238, 238, 0.6));
  background-position: 0 0, 20px 20px;
}

.EditKonvaCanvas {
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border: 4px dashed #aeaeae;
  box-sizing: content-box !important;
}

.EditKonvaCanvas .EditKonvaStage {
  box-sizing: border-box;
  align-items: center;
  justify-content: center;
  text-align: center;
  margin: 0 auto;
  background-size: 40px 40px;
  background-image:
    linear-gradient(45deg, rgba(238, 238, 238, 0.6) 25%, transparent 25%, transparent 75%, rgba(238, 238, 238, 0.6) 75%, rgba(238, 238, 238, 0.6)),
    linear-gradient(45deg, rgba(238, 238, 238, 0.6) 25%, transparent 25%, transparent 75%, rgba(238, 238, 238, 0.6) 75%, rgba(238, 238, 238, 0.6));
  background-position: 0 0, 20px 20px;
}

.Konva-buttons {
  margin-top: 10px;
  align-items: center;
  text-align: center;
}
.Konva-buttons button {
  width: 80px;
  height: 50px;
  background-color: transparent;
  color: white;
  border: 2px solid white;
  border-radius: 15px;
  margin-right: 10px;
}
.Konva-buttons button:hover {
  color: #F8A80E;
  border-color: #F8A80E;
  cursor: pointer;
}


@media (max-width: 768px) {
  .KonvaCanvas {
    width: 340px;
    height: 340px;
  }
  
  .KonvaCanvas .KonvaStage {
    width: 340px;
    height: 340px;
  }
  .KonvaCanvas .KonvaStage .konvajs-content {
    width: 340px !important;
    height: 340px !important;
  }
  .KonvaCanvas .KonvaStage canvas {
    width: 340px !important;
    height: 340px !important;
  }
  .Konva-buttons button {
    width: 65px;
    height: 45px;
    font-size: 10px;
  }
}