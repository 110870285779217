.ComparisonSlider {
  width: 100%;
  height: auto;
  aspect-ratio: 1 / 1;
  align-items: center;
  justify-content: center;
}
.comparison-slider {
  position: relative;
  width: 100%;
  height: 100%;
  margin: auto;
  user-select: none;
  overflow: hidden;
  touch-action: pan-x;
  border-radius: 25px;
}

.comparison-slider > figure {
  margin: 0;
  width: 100%;
  height: 100%;
  display: flex; /* Flexbox를 활용한 정렬 */
  align-items: center; /* 세로 중앙 정렬 */
  justify-content: center; /* 가로 중앙 정렬 */
}

.comparison-slider > figure:last-of-type {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  clip-path: polygon(50% 0, 100% 0, 100% 100%, 50% 100%);
}

.comparison-slider > figure > img {
  width: 100%;
  height: 100%;
  /* margin: auto; */
  display: block;
  object-fit: contain;
  object-position: center;
  pointer-events: none;
}


.comparison-slider > .landingslider {
  position: absolute;
  top: calc(50% - 20px);
  left: 50%;
  display: flex;
  width: 40px;
  height: 40px;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  transform: translate3d(-20px, 0, 0);
  background: #f1f1f1;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.45);
  text-align: center;
  cursor: grab;
}

.comparison-slider.dragging,
.comparison-slider.dragging > .landingslider {
  cursor: grabbing;
}

.comparison-slider.dragging > .landingslider {
  background: #c5aae3;
}

.comparison-slider > .landingslider > svg {
  pointer-events: none;
}