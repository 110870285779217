.UniteGenerater {
  margin-top: 56px;
  width: 100vw;
  height: calc(100vh - 64px);
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
}

.UniteGenerater h2 {
  font-size: 25px;
  font-weight: bolder;
  line-height: 1.4;
}

.UniteGenerater .con-column {
  display: flex;
  flex-direction: column;
  width: auto;
}

.UniteGenerater .con-row {
  display: flex;
  flex-direction: row;
  width: 100%;
}

.UniteGenerater .canvas-area {
  width: 600px;
  height: 600px;
  padding: 10px;
  box-sizing: border-box;
}
.UniteGenerater .combine-area {
  /* border: 1px solid rgba(255, 0, 0, 0.2); */
  width: 800px;
  padding: 0 10px;
  box-sizing: border-box;
}

.UniteGenerater .combine-area .combine-inner {
  margin: 30px 0;
}
.UniteGenerater .combine-area .bgvoid {
  width: 150px;
  height: 100px;
  border: 1px solid black;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: #D9D9D9;
  color: black;
  font-size: 20px;
  margin-right: 20px;
  cursor: pointer;
}
.UniteGenerater .combine-area .bgvoid:hover {
  font-size: 25px;
  transition: .2s;
}
.UniteGenerater .combine-area .combine-inner img{
  max-width: 150px;
  max-height: 150px;
  margin-right: 20px;
}
.UniteGenerater .combine-area .combine-inner .combine-content {
  display: flex;
  margin-top: 25px;
  align-items: center;
}


.UniteGenerater .combine-area button {
  width: auto;
  height: 50px;
  padding: 0px 20px;
  background-color: transparent;
  border: 1px solid white;
  color: white;
  font-size: 18px;
  font-weight: 600;
  margin-right: 20px;
  border-radius: 15px;
}
.UniteGenerater .combine-area button:hover {
  cursor: pointer;
  color: #F8A80E;
  border-color: #F8A80E;
}
.UniteGenerater .combine-area .uploadBtn {
  width: 150px;
  height: 150px;
}
.UniteGenerater .combine-area button.act {
  border: none;
  background-color: #3FD13C;
}
.UniteGenerater .combine-area button.dis {
  border-color: #8D8D8D;
  color: #8D8D8D;
  cursor: not-allowed;
}
.UniteGenerater .deleteBG {
  position: absolute;
  top: 0;
  right:20px;
  cursor: pointer;
  background-color: rgba(255, 255, 255, 0.2);
  border-radius: 50%;
  padding: 5px;
}
.UniteGenerater .deleteBG:hover {
  color: red;
}

.UniteGenerater .step-buttons {
  display: none;
}


@media (max-width: 768px) {
  .UniteGenerater {
    margin-top: 48px;
    width: 100vw;
    height: calc(100vh - 48px);
    flex-direction: column;
    justify-content: start;
    margin-bottom: 0px;
  }
  
  .UniteGenerater h2 {
    font-size: 14px;
    line-height: 1.4;
  }
    
  .UniteGenerater .con-row {
    flex-direction: column;
  }
  
  .UniteGenerater .canvas-area {
    width: 100vw;
    height: auto;
    max-width: 420px;
    aspect-ratio: 1 / 1;
    padding: 0px;
  }

  
  .UniteGenerater .combine-area {
    width: 100%;
    padding: 0 10px;
    text-align: center;
  }
  
  .UniteGenerater .combine-area .combine-inner {
    margin: 30px 0;
    display: none;
  }
  .UniteGenerater .combine-area .combine-inner.active {
    display: block;
  }
  .UniteGenerater .combine-area .combine-inner .combine-content {
    flex-direction: column;
    display: flex;
    margin-top: 25px;
    align-items: center;
  }
  .UniteGenerater .combine-area .combine-inner .combine-content .myproduct_preview {
    display: none;
  }

  .UniteGenerater .combine-area .bgvoid {
    width: 180px;
    height: 120px;
    font-size: 20px;
    margin-right: 0;
    margin-bottom: 20px;
  }
  .UniteGenerater .combine-area .combine-inner img{
    max-width: 150px;
    max-height: 150px;
    margin-right: 0;
    margin-bottom: 20px;
  }
  
  
  .UniteGenerater .combine-area button {
    width: 180px;
    height: 55px;
    border: 1px solid white;
    color: white;
    font-size: 18px;
    word-break: keep-all;
    margin-right: 0;
    border-radius: 8px;
  }
  .UniteGenerater .combine-area .uploadBtn {
    width: 180px;
    height: 180px;
  }

  .UniteGenerater .deleteBG {
    top: 0;
    right: 0;
  }


  .UniteGenerater .step-buttons {
    display: block;
    position: relative;
    width: 90%;

  }
  .UniteGenerater .step-buttons button {
    border: none;
    position: absolute;
    top: 80px;
    font-size: 40px;
    margin: 0;
    padding: 0;
    width: 50px;
  }
  .UniteGenerater .step-buttons .left {
    left: 0;
  }
  .UniteGenerater .step-buttons .right {
    right: -45px;
  }
}