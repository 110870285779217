/* App.css */
body {
  padding: 0;
  margin: 0;
  /* background-color: #111820; */
  background: linear-gradient(#111820, #193044, #111820);
  background-attachment: fixed;
  background-size: cover;
}
.app {
  position: relative;
  /* min-height: 100vh; */
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  /* overflow: hidden; */
}

.content {
  width: 100%;
  max-width: 1300px; /* Adjust as per your need */
  display: flex;
  gap: 24px; /* Adjust as per your need */
  margin-top: 24px;
  box-sizing: border-box;
}

.loading-popup {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

.loading-content {
  text-align: center;
  background-color: #ffffff;
  padding: 16px;
  border-radius: 4px;
}

.GoogleSDKContainer {
  display: none;
}

.custom-cursor {
  cursor: url('../public/brush-cursor.png') 16 16, pointer;
}

.modal {
  @apply bg-white p-6 md:p-8 m-4 rounded-lg shadow-lg relative;
}

.modal-overlay {
  @apply fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-[9999];
}

.modal-close {
  @apply absolute top-3 md:top-4 right-3 md:right-4 text-gray-500 hover:text-gray-700 focus:outline-none;
}

.modal-title {
  @apply w-full mb-8 text-center text-2xl font-bold text-gray-800;
}

.modal-content {
  @apply w-full flex flex-col text-sm md:text-base text-gray-700
}

.modal-text {
  @apply text-lg text-gray-700;
}

.btn-border-gradient {
  @apply rounded-full 
         bg-gradient-to-r from-blue-500 to-purple-600 
         transition-all duration-200 ease-in-out;
}

.btn-solid-white {
  @apply flex w-full h-full items-center justify-center 
         rounded-full bg-white text-black
         text-xl tracking-widest text-black whitespace-nowrap;
}

.btn-solid-white:hover {
  @apply bg-transparent text-white;
}