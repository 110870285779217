.StudioLanding {
  margin-top: 64px;
  min-height: calc(80vh - 64px);
  justify-content: center;
  align-items: center;
  text-align: center;
  display: flex;
  flex-direction: column;
}
.StudioLandingInner {
  justify-content: center;
  align-items: center;
  text-align: center;
  display: flex;
  flex-direction: column;
}
.StudioLandingInner .container {
  justify-content: center;
  align-items: center;
  text-align: center;
  margin: 0 auto;
  display: flex;
  gap: 50px;
}

.StudioLandingInner .intro-area {
  height: auto;
  width: 512px;
  color: white;
  box-sizing: border-box;
}
.StudioLandingInner .intro-area .mershikkeng {
  width: 512px;
  height: 512px;
  aspect-ratio: 1 / 1;
  margin-bottom: 20px;
}

/* .StudioLandingInner .intro-area h1 {
  font-size: 42px;
  font-weight: bolder;
  word-break: keep-all;
  line-height: 1.1;
}
.StudioLandingInner .intro-area h2 {
  font-size: 20px;
  font-weight: 500;
  word-break: keep-all;
  line-height: 1.8;
} */
.StudioLandingInner .canvas-area {
  height: auto;
  width: 800px;
  color: white;
  justify-content: center;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
}
.StudioLandingInner .canvas-area h1 {
  font-size: 90px;
  font-weight: bolder;
  word-break: keep-all;
  line-height: 1.1;
}
.StudioLandingInner .canvas-area h2 {
  font-size: 120px;
  font-weight: bolder;
  word-break: keep-all;
  line-height: 1.1;
}


.StudioLandingInner .upload-area {
  height: auto;
  width: 800px;
}

.StudioLandingInner .upload-area .canvas-area {
  width: 800px;
  height: auto;
}
.StudioLandingInner .upload-area .canvas-area canvas {
  width: 512px;
  height: 512px;
  border: 8px dashed #5a5a5a;
  background: url('../assets/images/CanvasContent1.png') no-repeat center center;
  background-position: 50% 50%;
  box-sizing: border-box;
}

/* .StudioLandingInner .getStart {
  display: none;
} */
 
.gradient-border-button {
  margin: 60px auto;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  padding: 11px 35px;
  z-index: 11;
  height: auto;
  font-size: 18px;
  font-weight: bolder;
  color: white;
  background-color: transparent; /* 버튼 내부 투명 */
  border-radius: 30px;
  position: relative;
  overflow: hidden; /* 버튼을 벗어나는 가상 요소를 숨김 */
}

.gradient-border-button:hover {
  scale: 1.03;
}

.gradient-border-button::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: linear-gradient(90deg, #3b82f6, #933bff);
  border-radius: inherit;
  padding: 2px; /* 내부 콘텐츠와 테두리 사이 간격 */
  -webkit-mask: 
    linear-gradient(#fff 0 0) content-box, 
    linear-gradient(#fff 0 0);
  mask: 
    linear-gradient(#fff 0 0) content-box, 
    linear-gradient(#fff 0 0);
  -webkit-mask-composite: xor;
  mask-composite: exclude;
  z-index: -1;
}

.StudioLandingInner .getStart:hover {
  border-color: #F8A80E;
  color: #F8A80E;
  cursor: pointer;
}

.relative-box {
  position: relative; /* 기준 위치 설정 */
  display: flex;
  justify-content: center;
  z-index: 10; /* 버튼을 다른 요소보다 위에 표시 */
}

.button-original {
  margin: 20px auto;
  display: block;
  position: absolute;
  width: 180px;
  height: 50px;
  background-color: #323232;
  font-size: 18px;
  font-weight: bolder;
  color: white;
  /* border: 2px solid white; */
  border-radius: 30px;
}

.button-done {
  margin: 20px auto;
  display: none;
  position: absolute;
  width: 180px;
  height: 50px;
  background: linear-gradient(to right, #3b82f6, #9333ea); /* 파란색에서 보라색으로 그라데이션 */
  font-size: 18px;
  font-weight: bolder;
  color: white;
  /* border: 2px solid white; */
  border-radius: 30px;
}

.StudioLandingInner .upload-area .canvas-area canvas {
  display: none;
}

.mobile {
  display: none;
}

@media (max-width: 768px) {
  .StudioLanding {
    margin-top: 72px;
    min-height: calc(100vh - 720px);
    width: 100%;
  }
  .StudioLandingInner {
    width: 100%;
  }
  .StudioLandingInner .container {
    flex-direction: column;
    gap: 10px;
  }

  .StudioLandingInner .intro-area {
    height: auto;
    width: 100%;
    aspect-ratio: 1 / 1;
  }
  .StudioLandingInner .intro-area .mershikkeng {
    margin: 0 auto 20px;
    width: 70%;
    height: auto;
    aspect-ratio: 1 / 1;
  }
  

  .StudioLandingInner .canvas-area h1 {
    font-size: 38px;
  }
  .StudioLandingInner .canvas-area h2 {
    font-size: 48px;
    line-height: 1.4;
  }

  .StudioLandingInner .upload-area {
    width: 360px;
    height: 180px;
  }
  
  .StudioLandingInner .upload-area .canvas-area {
    width: 360px;
    height: 180px;
  }
  /* .StudioLandingInner .upload-area .canvas-area canvas {
    display: none;
  } */
  .StudioLandingInner .getStart {
    margin: 20px auto;
    display: block;
    width: 180px;
    height: 50px;
    background-color: #2d8fff;
    font-size: 18px;
    font-weight: bolder;
    color: white;
    border: 2px solid white;
    border-radius: 30px;
  }
  .StudioLandingInner .getStart:hover {
    border-color: #F8A80E;
    color: #F8A80E;
    cursor: pointer;
  }
  .mobile {
    display: block;
  }
  .desktop {
    display: none;
  }
}